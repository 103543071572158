<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title> {{ poData.POCode }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >Purchase Order</v-subheader
                >
              </v-col>
            </v-row>

            <v-row class="mt-n2">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Entity Name</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>Davao del Norte State College</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">PO no.</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.POCode }}</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n8">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Supplier</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.CompanyName }}</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold"
                  >Place of Delivery</v-subheader
                >
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.podDescription }}</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n8">
              <v-col cols="3">
                <v-subheader class="font-weight-bold"
                  >Supplier Address</v-subheader
                >
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.CompanyAddress }}</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold"
                  >Date of Delivery</v-subheader
                >
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.dodDescription }}</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n8">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">TIN</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.TIN }}</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold"
                  >Delivery Term</v-subheader
                >
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.dtDescription }}</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n8 mb-4">
              <v-col cols="3">
                <v-subheader class="font-weight-bold"
                  >Mode of Procurement</v-subheader
                >
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.mopDescription }}</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Payment Term</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ poData.ptDescription }}</v-subheader>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="POItemID"
                class="elevation-1 mt-4"
                disable-pagination
                hide-default-footer
                show-select
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-2"
                      outlined
                      class="mb-2 ml-4"
                      @click="iar()"
                      :disabled="!formData.Lists.length"
                    >
                      IAR
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.ppmpItemID">
                      <td>
                        <v-checkbox
                          v-model="formData.Lists"
                          :value="item"
                          color="header"
                          style="margin: 0px; padding: 0px"
                          hide-details
                        />
                      </td>
                      <td>
                        <v-edit-dialog :return-value.sync="item.StockPropertyNo" large>
                          {{ item.StockPropertyNo }}
                          <template v-slot:input>
                            <v-textarea v-model="item.StockPropertyNo"> </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>
                          {{ item.ItemName }}
                      </td>
                      <td style="max-width: 200px">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.ItemDescription"
                        >
                          {{ item.ItemDescription }}
                          <template v-slot:input>
                            <v-textarea v-model="item.ItemDescription">
                            </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td>
                        <v-edit-dialog large :return-value.sync="item.UnitCost">
                          ₱ {{ formatPrice(item.UnitCost) }}
                          <template v-slot:input>
                            <v-textarea v-model="item.UnitCost"> </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td align="center">
                        {{ formatQty(item.Qty) }}
                      </td>
                      <td align="center">
                        {{ formatQty(item.IARQTY) }}
                      </td>
                      <td align="center">
                        {{ formatQty(item.Qty_waived) }}
                      </td>
                      <td align="center">
                        {{ formatQty(item.Qty_allowable) }}
                      </td>
                      <td align="center">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.editableQty"
                        >
                          <span
                            :class="
                              parseFloat(item.Qty_allowable) <
                              parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0
                                ? 'red--text'
                                : 'green--text'
                            "
                          >
                            {{ item.editableQty }}
                          </span>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.editableQty"
                              :rules="[parseFloat(item.Qty_allowable) >=
                              parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"
                              type="number"
                            >
                            </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td align="end">
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) *
                              parseFloat(item.editableQty)
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="PurposeDialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="">Inspection and Acceptance</span>
        </v-card-title>
        <v-card-text style="max-height: 900px;">
          <v-container>
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    dense
                    v-model="formData.InvoiceNo"
                    :rules="[formRules.required]"
                    color="header"
                    label="Invoice No."
                  ></v-text-field>

                  <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateInvoice"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateInvoice"
                        :rules="[formRules.required]"
                        label="Invoice Date"
                        readonly
                        v-bind="attrs"
                        multiple
                        chips
                        small-chips
                        v-on="on"
                        color="header"
                      >
                      <template
                      slot='selection'
                      slot-scope='data'>
                      <v-chip
                      @click="remove(data.item, 'invoice')"> 
                      {{data.item}}
                      </v-chip>
                      </template>
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateInvoice"
                      multiple
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu1.save(formData.DateInvoice)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    dense
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateDelivered"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateDelivered"
                        :rules="[formRules.required]"
                        label="Date Delivered"
                        readonly
                        v-bind="attrs"
                        multiple
                        chips
                        small-chips
                        v-on="on"
                        color="header"
                      >
                      <template
                      slot='selection'
                      slot-scope='data'>
                      <v-chip
                      @click="remove(data.item, 'delivered')"> 
                      {{data.item}}
                      </v-chip>
                      </template>
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateDelivered"
                      multiple
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey" @click="menu4 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu4.save(formData.DateDelivered)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <br />
                  <div class="font-weight-bold font-size-18">Inspection</div>
                  <v-divider class="mb-2"></v-divider>
                  <v-checkbox
                    v-model="formData.IsInspected"
                    color="green darken-2"
                    class=""
                  >
                    <template v-slot:label>
                      <div>Inspected</div>
                    </template>
                  </v-checkbox>
                  <v-menu
                    dense
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateInspectedStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateInspectedStart"
                        :rules="[formRules.required]"
                        label="Date Inspected (Start)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="header"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateInspectedStart"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu2.save(formData.DateInspectedStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    dense
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateInspectedEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateInspectedEnd"
                        :rules="[formRules.required]"
                        label="Date Inspected (End)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="header"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateInspectedEnd"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey" @click="menu3 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu3.save(formData.DateInspectedEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <br />
                  <div class="font-weight-bold font-size-18">Acceptance</div>
                  <v-divider class="mb-2"></v-divider>
                  <v-radio-group v-model="formData.Status" class="">
                    <v-radio
                      label="Complete Delivery"
                      color="green darken-2"
                      value="Complete"
                    ></v-radio>

                    <v-radio
                      label="Partial Delivery"
                      color="green darken-2"
                      value="Partial"
                    ></v-radio>
                  </v-radio-group>
                  <v-menu
                    dense
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateReceivedStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateReceivedStart"
                        :rules="[formRules.required]"
                        label="Date Received (Start)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="header"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateReceivedStart"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey" @click="menu5 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu5.save(formData.DateReceivedStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    dense
                    ref="menu6"
                    v-model="menu6"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateReceivedEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateReceivedEnd"
                        :rules="[formRules.required]"
                        label="Date Received (End)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="header"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateReceivedEnd"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey" @click="menu6 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu6.save(formData.DateReceivedEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-autocomplete
                    v-model="formData.RecieverUserID"
                    item-text="name"
                    item-value="UserID"
                    :rules="[formRules.required]"
                    :items="users"
                    dense
                    class="mb-4"
                    label="Select Receiver"
                    color="header"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="PurposeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-2" :loading="loadingBtn" dark @click="submit()">
            <v-icon class="mr-1">mdi-check-circle</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    poData: {},
  },
  data: () => ({
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    formData: {
      Lists: [],
      InvoiceNo: null,
      DateInspectedStart: null,
      DateInspectedEnd: null,
      RecieverUserID: null,
      DateInvoice: [],
      Status: null,
      IsInspected: null,
      DateDelivered: [] 
    },
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    itemList: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "Qty (Overall)", value: "Qty", sortable: false, align: "center" },
      { text: "Qty (IAR)", value: "IARQTY", sortable: false, align: "center" },
      { text: "Qty (Waived)", value: "Qty_waived", sortable: false, align: "center" },
      { text: "Qty (Allowable)", value: "Qty_allowable", sortable: false, align: "center" },
      { text: "Qty (Editable)", value: "editableQty", sortable: false, align: "center" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
    ],
    loadingBtn: false,
  }),
  watch: {
    poData: {
      handler(data) {
        if (data.POID) {
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    iar() {
      let error = false;
        for (let list of this.formData.Lists) {
          if (parseFloat(list.Qty_allowable) < parseFloat(list.editableQty) || parseFloat(list.editableQty) <= 0)
          {
            error = true;
          }
          }
          if (error) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message =
              "Failed to continue. Please check the details.";
            this.PurposeDialog = false;
            this.loadingBtn = false;
          } else {
        this.PurposeDialog = true;
          }
    },
    submit() {
      if (this.$refs.Formref.validate()) {
        this.loadingBtn = true;
        let error = false;
        for (let list of this.formData.Lists) {
          if (parseFloat(list.Qty_allowable) < parseFloat(list.editableQty) || parseFloat(list.editableQty) <= 0)
          {
            error = true;
            this.loadingBtn = false;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
          this.PurposeDialog = false;
          this.loadingBtn = false;
        } else {
          let data = new FormData();
          data.append("PODATA", JSON.stringify(this.poData));
          data.append("List", JSON.stringify(this.formData.Lists));
          data.append("InvoiceNo", this.formData.InvoiceNo);
          data.append("DateInspectedStart", this.formData.DateInspectedStart);
          data.append("DateInspectedEnd", this.formData.DateInspectedEnd);
          data.append("RecieverUserID", this.formData.RecieverUserID);
          data.append("DateInvoice",  JSON.stringify({...this.formData.DateInvoice}));
          data.append("Status", this.formData.Status);
          data.append("IsInspected", this.formData.IsInspected == true ? 1 : 0);
          data.append("DateDelivered", JSON.stringify({...this.formData.DateDelivered}));
          data.append("DateReceivedStart", this.formData.DateReceivedStart);
          data.append("DateReceivedEnd", this.formData.DateReceivedEnd);
          this.axiosCall("/iar/add", "POST", data).then((res) => {
            this.$refs.Formref.reset();
            this.PurposeDialog = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Success!";
            this.fadeAwayMessage.header = "System Message";
            this.loadingBtn = false;
            this.clickCloseMainDialogPR();
            window.open(this.apiUrl + "/iar/report/" + res.data.data + "/new");
          });
        }
      }
    },
    clickCloseMainDialogPR() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeIAR", false);
    },

    getItems() {
      this.formData.Lists = [];
      let data = new FormData();
      data.append("POID", this.poData.POID);
      this.axiosCall("/po/getItems/iar", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        this.users = res.data.data.users;
      });
    },
    remove(date, field) {
      let data = [];
      if(field == 'invoice'){
        data = this.formData.DateInvoice;
      }
      else if(field == 'delivered'){
        data = this.formData.DateDelivered;
      }
      var index = data.findIndex(x => x===date)
       data.splice(index,1);
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>